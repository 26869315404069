<template>

  <body>

    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">

        <!-- Header Section -->
        <Front-Header> </Front-Header>

        <div id="blog">
          <!-- Main Start-->
          <section class="hero_section height-half text-center">
            <!--<img src="./../assets/front/image/main_banner.jpg" style="position: absolute;">-->
            <img :src="pages.image" style="position: absolute;" :alt="pages.title">
            <div class="container">
              <h1 data-aos="fade-right" data-aos-duration="1200"> <span>{{  pages.title  }}</span></h1>
              <p data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">{{  pages.short_description  }}</p>
              <!--<a href="#" class="btn btn-primary" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">Explore Free</a>--->
            </div>
          </section>




          <!-- Main section  -->
          <div class="blog-wrapper private-blog-section">
            <div class="site-content container" id="introactive-blog" style="transform: none">
              <div class="row justify-content-center">
                <div class="col-xl-10">
                  <div class="section-title text-center mb-10 mb-lg-10 px-lg-3">
                    <h2 class="title gr-text-4">{{  pages.title  }}</h2>
                  </div>
                </div>
              </div>
              <div class="row" style="transform: none">
                <div class="col-lg-12">
                  <div class="content-area">
                    <main class="site-main">
                      <div class="row posts-wrapper">
                        <div class="col-md-4 grid-item" v-for="item in items" :key="item._id">
                          <article id="post-43953"
                            class="post post-43953 type-post status-publish format-standard has-post-thumbnail sticky hentry category-app"
                            @click="redirect(item.id)">
                            <div class="entry-media with-placeholder" style="padding-bottom: 61.904761904762%">

                              <img class="lazyautosizes lazyloaded" data-srcset="item.image" data-sizes="auto"
                                alt="app tricks" title="item.title" sizes="405px" :src="item.image">


                            </div>


                            <div class="entry-wrapper">
                              <header class="entry-header">
                                <div class="entry-category">
                                  {{  item.category  }}
                                </div>
                                <div class="entry-author child-theme-post-author">
                                  <div class="author-info">
                                    <span class="entry-date"><time datetime="2020-11-26T09:00:27+04:00">{{  item.created 
                                        }}</time></span>
                                    <!--<a class="author-name" href="https://ae.theentertainerme.com/author/charlotteb/">- By Charlotte Bright</a> -->

                                  </div>
                                </div>
                                <div class="entry-meta"></div>
                              </header>
                              <h3 class="article-title">
                                {{  item.title  }}
                              </h3>
                              <div class="entry-excerpt">
                                <p>{{  item.short_description  }}</p>
                              </div>
                            </div>

                          </article>

                        </div>


                      </div>


                      <!--<nav
              class="navigation posts-navigation"
              role="navigation"
              aria-label="Posts"
              style="display: none"
            >
              <h2 class="screen-reader-text">Posts navigation</h2>
              <div class="nav-links">
                <div class="nav-previous">
                  <a href="https://ae.theentertainerme.com/page/2/"
                    >Older posts</a
                  >
                </div>
              </div>
            </nav>
            <div class="infinite-scroll-status">
              <div class="infinite-scroll-request"></div>
            </div>
            <div class="infinite-scroll-action" style="text-align: center">
              <div class="bottom-button">
                <button class="infinite-scroll-button button btn btn-primary">
                  Load more
                </button>
                <button class="blog-filter-btn btn btn-primary">
                  Show Deals
                </button>
              </div>
            </div>-->


                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Main section  -->

        </div>


        <!-- Footer Section -->
        <Front-Footer> </Front-Footer>

      </div>
    </div>

  </body>
</template>

<script>
import VueMeta from 'vue-meta'
import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import Vue from 'vue'
import { db } from '@/main'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
Vue.use(VueMeta)
export default {
  components: {
    'Front-Header': Header,
    'Front-Footer': Footer,
    'Loader': Loader,
  },

  data() {
    return {
      items: [],
      category: '',
      subscriptionid: '',
      pages: {},
      checked: [],
      meta_title: '',
      meta_description: '',
    }
  },
  metaInfo() {
    //alert();
    return {
      title: "Intro Insider",
      meta: [
        { name: 'description', content: 'insider' },
      ]
    }
  },

  created: function () {
    this.fetchPages();
    this.fetchItems();
    this.fetchsubscription();
  },


  methods: {

    editModal: function () {
      console.log(this.data_id);
    },

    fetchPages() {

      db.collection("pages").doc('8fTjCMErCGgOCXGOIknf')
        .get()
        .then((doc) => {
          console.log('hhhh88');
          console.log(doc.id, " => ", doc.data());
          this.pages = doc.data();

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });


    },
    fetchItems() {

      var id = localStorage.getItem("userSessionId");
      db.collection("blogs")
        .where("blogshowoption", "==", 1)
        //.orderBy("created", "desc")
        .get()
        .then((querySnapshot) => {
          console.log('hhhh');

          querySnapshot.forEach((doc) => {

            console.log(doc.id, " => ", doc.data());

            this.items.push({
              id: doc.id,
              category: "The Intro Insider",
              title: doc.data().title,
              short_description: doc.data().short_description,
              image: doc.data().image,
              created: doc.data().created.split(",")[0],
            });



          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      if (id) {
        db.collection("order_details")
          //.orderBy("created", "desc")
          .get()
          .then((querySnapshot) => {
            console.log('hhhh');

            querySnapshot.forEach((doc) => {
              if (doc.data().uid == id) {
                this.subscriptionid = doc.data().subscription;
                db.collection('subscriptions').doc(this.subscriptionid).get()
                  .then((doc) => {
                    if (doc.data().blogshowoption == 1) {

                      this.checked.push("Kiwi");

                    }
                  })
                  .catch((error) => {
                    console.log("Error getting documents: ", error);
                  });
              }


            });

          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }

    },
    redirect(paramContactID) {
      console.log('insider console')
      var id = localStorage.getItem("userSessionId");
      if (id) {
        if (this.checked.includes("Kiwi")) {

          this.$router.push('/single-blog/' + paramContactID);
        } else {
          this.showNotification('Error,You must purchase the following subscription to view the Intro Insider Blog!', 'AlertTriangleIcon', 'danger');
          this.$router.push('/explore/');
        }
      } else {
        this.showNotification('Error,You must login  to view the Intro Insider Blog!', 'AlertTriangleIcon', 'danger');
      }

    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  }
}


</script>
<style>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.entry-media.with-placeholder img {
  min-height: 235px;
  max-height: 235px;
  display: block;
  width: 100%;
  object-fit: cover;
}

.grid-item .entry-category {
  background: #03e1bc;
  padding: 2px 9px;
  font-size: 10px;
  border-radius: 50px;
  color: #fff;
}

.site-main .posts-wrapper .post h3.article-title {
  font-size: 20px;
  margin-bottom: 0;
}

div#introactive-blog .entry-excerpt p {
  min-height: auto;
  font-size: 14px;
}
</style>
